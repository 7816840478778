import React, { useState } from "react";
import "./App.css";
import Nav from "./nav/nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Solutions from "./solutions/solutions";
import SideMenu from "./sideMenu/sideMenu";
import Privacy from "./privacy/privacy";
import Terms from "./terms/terms";
import Pricing from "./pricing/pricing";
import News from "./news/news";
import Home from "./home/home";
import Docs from "./docs/docs";
import PageNotFound from "./notFound/notFound";
import Foot from "./footer/footer";
import AddanAmazonOpenSearchServerlessVectorStore from "./docs/AddanAmazonOpenSearchServerlessVectorStore/AddanAmazonOpenSearchServerlessVectorStore";
import AddanAzureAiSearchVectorStore from "./docs/AddanAzureAiSearchVectorStore/AddanAzureAiSearchVectorStore";
import AddaPrompt from "./docs/AddaPrompt/AddaPrompt";
import BulkAddPrompts from "./docs/BulkAddPrompts/BulkAddPrompts";
import LLMsOverview from "./docs/LLMsOverview/LLMsOverview";
import BulkDeletePrompts from "./docs/BulkDeletePrompts/BulkDeletePrompts";
import ByoAOAI from "./docs/byoAOAI/ByoAOAI";
import ByoBedrock from "./docs/byoBedrock/ByoBedrock";
import ByoOAI from "./docs/byoOAI/ByoOAI";
import RAGOverview from "./docs/RAGOverview/RAGOverview";
import AddaRAG from "./docs/AddaRAG/AddaRAG";
import LatencyOverview from "./docs/LatencyOverview/LatencyOverview";
import RunLatencyTest from "./docs/runLatencyTest/runLatencyTest";
import HumanEvalOverview from "./docs/HumanEvalOverview/HumanEvalOverview";
import RunHumanEvalTest from "./docs/runHumanEvalTest/runHumanEvalTest";
import AutoEvalOverview from "./docs/AutoEvalOverview/AutoEvalOverview";
import RunAutoEvalTest from "./docs/runAutoEvalTest/runAutoEvalTest";
import ModelEoL from "./docs/modelEoL/modelEoL";

function App() {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const toggleSideMenu = () => {
    setShowSideMenu((prev) => !prev);
  };

  const hideSideMenu = () => {
    setShowSideMenu(false);
  };

  return (
    <Router>
      <main>
        <Nav onHamburgerClick={toggleSideMenu} />
        {showSideMenu && <SideMenu />}
        <Routes>
          <Route path="/" element={<Home onClick={hideSideMenu} />} />
          <Route
            path="/solutions/"
            element={<Solutions onClick={hideSideMenu} />}
          />
          <Route
            path="/pricing/"
            element={<Pricing onClick={hideSideMenu} />}
          />
          <Route path="/news/" element={<News onClick={hideSideMenu} />} />
          <Route path="/docs/" element={<Docs onClick={hideSideMenu} />} />
          <Route
            path="/privacy/"
            element={<Privacy onClick={hideSideMenu} />}
          />
          <Route path="/terms/" element={<Terms onClick={hideSideMenu} />} />
          <Route path="*" element={<PageNotFound onClick={hideSideMenu} />} />
          <Route
            path="/docs/add-an-amazon-opensearch-serverless-vector-store/"
            element={
              <AddanAmazonOpenSearchServerlessVectorStore
                onClick={hideSideMenu}
              />
            }
          />
          <Route
            path="/docs/add-an-azure-aisearch-vector-store/"
            element={<AddanAzureAiSearchVectorStore onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/add-a-prompt/"
            element={<AddaPrompt onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/bulk-add-prompts/"
            element={<BulkAddPrompts onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/bulk-delete-prompts/"
            element={<BulkDeletePrompts onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/llms-overview/"
            element={<LLMsOverview onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/byo-aoai/"
            element={<ByoAOAI onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/byo-bedrock/"
            element={<ByoBedrock onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/byo-oai/"
            element={<ByoOAI onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/rag-overview/"
            element={<RAGOverview onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/add-a-rag/"
            element={<AddaRAG onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/latency-overview/"
            element={<LatencyOverview onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/run-a-latency-test/"
            element={<RunLatencyTest onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/human-eval-overview/"
            element={<HumanEvalOverview onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/run-a-human-eval-test/"
            element={<RunHumanEvalTest onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/auto-eval-overview/"
            element={<AutoEvalOverview onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/run-an-auto-eval-test/"
            element={<RunAutoEvalTest onClick={hideSideMenu} />}
          />
          <Route
            path="/docs/model-eol/"
            element={<ModelEoL onClick={hideSideMenu} />}
          />
        </Routes>
        <Foot />
      </main>
    </Router>
  );
}

export default App;
