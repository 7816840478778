import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function ModelEoL(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - LLM Overview</title>
        <meta
          name="description"
          content="Explore Dataster's strategies to plan for the end of life of a model. Learn how to manage your models effectively and ensure smooth transitions."
        />
        <link rel="canonical" href="https://www.dataster.com/docs/model-eol/" />
      </Helmet>
      <div className="page-container" onClick={onClick} id="top">
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>
                Model End-Of-Life Planning
              </h2>
            </div>
            <div>
              <p>
                Strategic AI Asset Management is a key part of LLMOps and
                GenAIOps. A crucial aspect of this is planning for the lifecycle
                of models, especially as cloud providers retire specific models.
                When this happens, your API calls may start returning errors,
                potentially disrupting your operations. Dataster allows you to
                visualize upcoming retirement or end-of-life dates for your
                models across various cloud providers. It also helps you
                identify suitable replacement candidates, ensuring you have
                ample time for proper planning, benchmarking, and making
                informed decisions. With this feature, you can seamlessly
                transition to new models without unexpected surprises,
                maintaining the smooth operation of your production environment.
              </p>
              <br />

              <p>
                The models that Dataster provides access to out-of-the-box are
                shared. For private access, users can bring their own models
                into Dataster as assets, ensuring that only they can access
                those models. The catalog displays Bring Your Own (BYO) LLMs
                differently from the Dataster-provided LLMs. Instead of showing
                the Dataster badge, it gives the user the option to edit or
                delete the model from the catalog.
              </p>

              <br />
              <ImageGallery
                image="/documentation/vsBYOLlm.png"
                alt="Dataster provided vs BYO LLM"
                title="Dataster provided vs BYO LLM"
              />
              <br />

              <p>
                Model End-Of-Life Planning allows you to visualize the upcoming
                retirement or end-of-life dates for your models across several
                cloud providers for both the Dataster-provided models and the
                BYO models.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Dataster account.</li>
                  <li>Optionally, one or more BYO Models.</li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the Lifecycle Page
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Navigate to the Lifecycle page by clicking "Lifecycle" in
                    the left navigation pane.
                  </li>
                </ol>
              </p>

              <h3 style={{ margin: "1rem 0" }}>Step 2: Strategize</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Note the upcoming retirement or end-of-life dates for your
                    models.
                  </li>
                  <li>
                    Hover over the models that concern you and identify
                    potential candidates.
                  </li>
                  <li>
                    Run performance tests on the candidates to ensure they meet
                    your requirements.
                  </li>
                </ol>
              </p>

              <br />
              <ImageGallery
                image="/documentation/eolPlanning.png"
                alt="Model End-Of-Life Planning"
                title="Model End-Of-Life Planning"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                By effectively planning for the end-of-life of your models, you
                can ensure a smooth transition and maintain the reliability of
                your operations. Dataster's Model End-Of-Life Planning feature
                provides you with the tools to visualize upcoming retirement
                dates, identify suitable replacement candidates, and perform
                necessary performance tests. This proactive approach helps you
                avoid unexpected disruptions and keeps your production
                environment running seamlessly. Whether using Dataster-provided
                models or your own, you can confidently manage your AI assets
                and stay ahead of any potential challenges.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
